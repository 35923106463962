@import url("https://fonts.googleapis.com/css2?family=B612+Mono&family=Cutive+Mono&family=Noto+Sans&family=Roboto&display=swap");

.palette {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  overflow-x: hidden;
  background: #303030;
  padding: 10px;
  z-index: 999999;
  padding-top: 50px;
  min-width: 951px;
  .MuiTabs-vertical {
    width: 30%;
  }

  .tab-panel {
    width: 100%;
  }

  .ajax-manager,
  .state-manager,
  .ux-manager {
    margin-top: 4px;
    border: 1px solid #01579b;
    padding: 12px;
    position: relative;
    button:not(.btn_settingClose),
    select,
    input {
      width: 100%;
      padding: 8px;
      margin-bottom: 8px;
      &.last {
        margin-bottom: 0;
      }
    }
  }

  .ajax-manager,
  .state-manager {
    textarea {
      padding: 8px;
      width: 100%;
      border: 1px solid #01579b;
      border-radius: 5px;
      &:focus {
        outline: 1px solid #01579b;
      }
    }
    input {
      border: 1px solid #01579b;
      border-radius: 5px;
      &:focus {
        outline: 1px solid #01579b;
      }
    }
    .object-container {
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      input {
        height: 40px;
      }
      input:first-child {
        width: 150px;
        margin-right: 8px;
      }
      button {
        width: calc(100% - 158px);
        height: 40px;
      }
      input:last-child {
        width: calc(100% - 158px);
      }
    }
  }

  .ux-manager {
  }

  .ajax-manager {
    .h {
      font-size: 20px;
      font-weight: bold;
    }
    .data-box {
      border: 1px solid #1a237e;
      border-radius: 10px;
      padding: 8px;
      margin-bottom: 8px;
      margin-top: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      table {
        width: 100%;
        overflow: hidden;
      }
      table th {
        padding: 8px;
        border-bottom: 1px solid #1a237e;
      }
      table tr td {
        white-space: pre-line;
        padding: 8px;
        text-align: center;
        border-bottom: 1px solid #1a237e;
        line-break: anywhere;
        width: 50%;
      }
    }
  }
  #list {
    margin: 0;
    margin-top: 1rem;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      margin-right: 4px;
      margin-bottom: 4px;
      box-shadow: 0 20px 35px rgba(0, 0, 0, 0.3);
      overflow: hidden;
      position: relative;

      &.active::before {
        content: "";
        height: 150%;
        width: 150%;
        position: absolute;
        background: conic-gradient(
          #fff,
          #fd004c,
          #fe9000,
          #fff020,
          #3edf4b,
          #3363ff,
          #fff
        );
        left: -25%;
        top: -25%;
        animation: spin 1.2s infinite linear;
      }

      &.active::after {
        content: "";
        position: absolute;
        background-color: inherit;
        height: 85%;
        width: 85%;
        top: 7.5%;
        left: 7.5%;
        border-radius: 4px;
        display: grid;
        place-items: center;
      }
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }
  .btn_settingClose {
    position: absolute;
    right: 13px;
    top: 12px;
    width: 26px;
    height: 26px;
    // background: url("https://d10erikh2ay4p8.cloudfront.net/common/btn_close_w.png") center center no-repeat;
    line-height: 300px;
    overflow: hidden;
    background-size: 16px 16px;
    padding: 5px;
    transform: rotate(45deg);
    background: transparent;
  }

  .btn_settingClose:before {
    content: "";
    display: block;
    border-left: 1px solid #fff;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .btn_settingClose:after {
    content: "";
    display: block;
    border-top: 1px solid #fff;
    width: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#setting.active {
  /* visibility: visible; */
  transform: none;
}

.btn_settingOpen {
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 50px;
  height: 40px;
  border: 1px solid #000;
  background-color: transparent;
  font-size: 12px;
}
